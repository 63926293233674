import React from 'react';
import withDataroomGlobalProps from '@/dataroom/infrastructure/next/withDataroomGlobalProps';
import Layout from '@/dataroom/ui/components/Landing/investorSet';
import HomeComponent from '@/dataroom/ui/components/Landing/investorSet/Home';

export const getServerSideProps = withDataroomGlobalProps(() => ({ props: {} }));

const Home = () => (
  <Layout>
    <HomeComponent />
  </Layout>
);

export default Home;
